import Vue from 'vue';
import Vuex from 'vuex';
import router from '../router';
import axios from 'axios';
import moment from 'moment';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		currentPageId: null,
		idNewCalculation: '',
		serviceComponent: {},
		calculations: [],
		calculationTitle: '',
		groupsState: [
			{
				name: 'Группа устройств',
				device: '',
				deviceTitle: '',
				comments: '',
				filterString: '',
				filterStringMultiselect: '',
				conveyorsBooleanExtraPrice: 0,
				mdmBooleanExtraPrice: 0,
				lifeCycleBooleanExtraPrice: 0,
				conveyorsSelectString: '',
				filterStringConveyor: '',
				filterStringMdm: '',
				filterStringMdmInput: '',
				filterStringConveyorInput: '',
				filterStringLifecycle: '',
				finalFilterString: '',
				countDevice: null,
				priceDevice: null,
				pageId: null,
				mdmInput: [
					{
						count: '',
						type: 'input',
						title: 'Тех. обслуживание MDM, 2 000₽/час. ',
						time: '30 ч./мес.',
						rate: 2000,
						price: '60 000₽/мес.',
						description: 'Решение не стандартных задач, доработка модулей решение проблем с тех поддержкой KNOX',
					},
					{
						count: '',
						type: 'input',
						title: 'Обслуживание запросов-заявок, 800₽/час.',
						time: '60 ч./мес.',
						rate: 800,
						price: '48 000₽/мес.',
						description: 'Выполнение задач по запросу заказчика, сброс устр. поиск места нахождения и тд.',
					},
				],
				conveyorsHour: [
					{
						count: '',
						order: 1,
						type: 'hour',
						rate: 3000,
						name: 'Составление инструкций, 3000 ₽/час.',
						inputName: '',
						tooltip: '',
						subText: 'Составление инструкции по настроке устройств по эталонному образцу'
					},
					{
						count: '',
						order: 7,
						type: 'hour',
						rate: 4000,
						name: 'Проведение обучения, 4 000₽/час. ',
						inputName: '',
						tooltip: '',
						subText: 'Проведение обучения и подготовка обучающих  материалов'
					},
				],
				servicesSelect: [
					{
						order: 2,
						type: 'select',
						id: 2,
						name: 'Срок гарантийного обслуживания',
						inputName: '',
						subText: 'Количество гарантийных инцидентов в течение срока гарантийного обслуживания не огранично',
						tooltip: '',
						values: [
							{
								index: '0',
								name: 'Срок гарантийного обслуживания',
								selected: true
							},
							{
								index: '1',
								name: '1 год',
								selected: false
							}, {
								index: '2',
								name: '2 года',
								selected: false
							}, {
								index: '3',
								name: '3 года',
								selected: false
							}
						]
					},
					{
						order: 3,
						type: 'select',
						id: 3,
						name: 'Срок не гарантийного обслуживания',
						inputName: '',
						subText: 'Количество гарантийных инцидентов в течение срока гарантийного обслуживания не огранично',
						tooltip: '',
						title: 'Срок не гарантийного обслуживания',
						values: [
							{
								index: '0',
								name: 'Срок не гарантийного обслуживания',
								selected: true
							},
							{
								index: '1',
								name: '1 год',
								selected: false
							}, {
								index: '2',
								name: '2 года',
								selected: false
							}, {
								index: '3',
								name: '3 года',
								selected: false
							}
						]
					},
					{
						type: 'select',
						id: 4,
						name: 'Логистика',
						inputName: '',
						subText: 'Забор устройства и обратная доставка столько раз сколько гарантийных и не гарантийных ремонтов и замен предусмотрено выбранными опциями',
						tooltip: '',
						title: 'Логистика',
						values: [
							{
								index: '0',
								name: 'Логистика',
								selected: true
							},
							{
								index: '1',
								name: 'Логистика по Москве',
								selected: false
							}, {
								index: '2',
								name: 'Логистика по России',
								selected: false
							}
						]
					},
					{
						'type': 'select',
						'id': 3,
						'name': 'Кол-во не гарантийных инцидентов в течение срока обслуживания',
						'inputName': '',
						'subText': 'Количество гарантийных инцидентов в течение срока гарантийного обслуживания не огранично. Устройство в рамках программы или заменяется и больше не ремонтируется, или ремонтируется, но в случае последней замены стоимость предыдущего ремонта возвращается обратно.',
						'tooltip': '',
						'title': 'Кол-во не гарантийных инцидентов в течение срока обслуживания',
						'values': [
							{
								index: '0',
								name: 'Кол-во не гарантийных инцидентов в течение срока обслуживания',
								selected: true
							}, {
								index: '1',
								'name': '1 ремонт',
								selected: false
							}, {
								index: '2',
								'name': '2 ремонта',
								selected: false
							}, {
								index: '3',
								'name': '3 ремонта',
								selected: false
							}, {
								index: '4',
								'name': '1 ремонт / 1 замена (замена закрывает договор)',
								selected: false
							}, {
								index: '5',
								'name': '2 ремонта / 1 замена / 1 ремонта + 1 замена (замена закрывает договор)',
								selected: false
							}, {
								index: '6',
								'name': '3 ремонта / 1 замена / 2 ремонта + 1 замена (замена закрывает договор)',
								selected: false
							}
						]
					}
				],
				programPackage: [],
				selectedProgram: null,
				selectedProgramPrice: null,
				extraMoney: null,
				lifeCycleDevice: [
					{
						title: 'Утилизация',
						price: '250'
					}, {
						title: 'Удаление данных устройств',
						price: '800'
					}, {
						title: 'Обратный выкуп, Trade-in 1 год',
						price: '480',
						buyout: true,
					},
				],
				devices: [
					{
						index: '0',
						name: 'Выберете тип устройства',
						selected: true
					},
					{
						selected: false,
						name: 'Планшет',
						index: '1',
					},
					{
						selected: false,
						name: 'Смартфон',
						index: '2',
					}, {
						selected: false,
						name: 'Apple IMac (Включая все СТО модели) начиная с моделей Late 2019',
						index: '3',
					}, {
						selected: false,
						name: 'Apple MacPro (Включая все СТО модели) начиная с моделей Late 2019',
						index: '4',
					}, {
						selected: false,
						name: 'Apple MacMini (Включая все СТО модели) начиная с моделей Late 2019',
						index: '5',
					}, {
						selected: false,
						name: 'Apple MacBook Pro 15’’|16’’ (Включая все СТО модели) начиная с моделей Late 2019',
						index: '6',
					}, {
						selected: false,
						name: 'Apple Apple MacBook Air | MacBook Pro 13’’ (Включая все СТО модели) начиная с моделей Late 2019',
						index: '7',
					}, {
						selected: false,
						name: 'Apple iPhone 8, iPhone 7 и iPhone 6s',
						index: '8',
					}, {
						selected: false,
						name: 'Apple iPhone 11, iPhone XR, iPhone 8 Plus, iPhone 7 Plus и iPhone 6s Plus',
						index: '9',
					}, {
						selected: false,
						name: 'Apple iPhone 11 Pro, iPhone 11 Pro Max, iPhone XS, iPhone XS Max, and iPhone X',
						index: '10',
					}
				],
				servicesMultiselect: {
					order: 5,
					type: 'multiselect',
					id: 4,
					name: 'Где будут обрабатываться заявки',
					inputName: '',
					tooltip: '',
					subText: 'Call - Centr по номеру 8 800 дуступен всем Сервисным программам (СП)',
					values: [
						{
							name: 'Работа в системе трекинга заявок Заказчика',
							price: '60₽ за устр./мес.',
							percent: '',
							percentForWhat: '',
							forWhat: 'Доступно только в гибкой СП',
							selected: false,
						},
						{
							name: 'Персональный Личный кабинет Покупателя',
							price: 'Бесплатно',
							percent: '0,03%',
							percentForWhat: 'Для гибкой СП',
							forWhat: 'Для iSurance',
							selected: false,
						}
					]
				},
				conveyorsBoolean: [
					{
						order: 2,
						type: 'bool',
						id: 6,
						name: 'Базовый конвейер. Новые устройства',
						price: '480',
						inputName: '',
						tooltip: '',
						subText: '',
						selected: false,
					},
					{
						order: 1,
						type: 'bool',
						id: 5,
						name: 'Первичная загрузка приложений на устройство по списку от заказчика.',
						price: '216',
						inputName: '',
						tooltip: '',
						subText: '',
						selected: false,
					}, {
						order: 3,
						type: 'bool',
						id: 7,
						name: 'Устройства со склада Заказчика, возможно Б/У',
						price: '144',
						inputName: '',
						tooltip: '',
						subText: '',
						selected: false,
					},
				],
				mdmCheckbox: [
					{
						type: 'checkbox',
						title: 'Лицензии Knox Manage',
						price: '3240',
						selected: false
					},
				],
			}
		],
		
	},
	mutations: {
		setCurrentPageId(state, payload) {
			state.currentPageId = payload;
		},
		setIdCalculation(state, payload) {
			state.idNewCalculation = payload;
		},
		setTitleCalculation(state, payload) {
			state.calculationTitle = payload;
		},
		changeCalculationInfo(state, payload) {
			state.calculations = payload;
		}
	},
	actions: {
		CREATE_USER() {
			axios({
				method: 'get',
				url: 'http://calc.tki.ltd:3005/usercreate',
			});
		},
		GET_ALL_CALCULATION({state}) {
			axios({
				method: 'get',
				url: 'http://calc.tki.ltd:3005/calculation',
			})
			.then(response => {
				state.calculations = response.data;
			})
			.catch(error => {
				console.log(error);
			});
		},
		DELETE_CALCULATION({}, calculationId) {
			axios({
				method: 'DELETE',
				url: `http://calc.tki.ltd:3005/calculation/${calculationId}`,
			});
		},
		CREATE_DUPLICATE_CALCULATION({commit, state}, payload) {
			const duplicateTitle = payload.name;
			axios({
				method: 'post',
				url: 'http://calc.tki.ltd:3005/calculation',
				data: {
					name: payload.name,
					cost: payload.cost || 0,
					bundleInfo: {}
				}
			})
			.then(response => {
				commit('setIdCalculation', response.data.billId);
				commit('changeCalculationInfo', []);
				router.push({path: `devices?pageId=${state.idNewCalculation}`, query: {duplicateTitle, numberIsMainPage: payload.numberIsMainPage.toString()}});
				router.go(0);
			})
			.catch(error => {
				console.log(error);
			});
		},
		CREATE_CALCULATION({commit}) {
			return new Promise(resolve => {
				axios({
					method: 'post',
					url: 'http://calc.tki.ltd:3005/calculation',
					data: {
						'name': 'Рассчет тестовый 100500',
						'cost': '1500',
						'bundleInfo': {}
					}
				})
				.then(response => {
					let pageId;
					commit('setIdCalculation', response.data.billId);
					commit('changeCalculationInfo', []);
					pageId = response.data.billId;
					router.push({path: 'devices', query: {pageId}});
					resolve();
				})
				.catch(error => {
					console.log(error);
				});
			});
		},
		GET_CALCULATION({commit, state}, calculationId) {
			return new Promise(resolve => {
				axios({
					method: 'get',
					url: `http://calc.tki.ltd:3005/calculation/${calculationId}`,
				})
				.then(response => {
					commit('changeCalculationInfo', JSON.parse(response.data.bundleInfo));
					commit('setTitleCalculation', response.data.name);
					resolve();
				})
				.catch(error => {
					console.log(error);
				});
			})
			
		},
		SAVE_CALCULATION({state}, cost) {
			axios({
				method: 'put',
				url: `http://calc.tki.ltd:3005/calculation/${state.idNewCalculation}`,
				data: {
					name: state.calculationTitle || `Новый рассчет-${state.idNewCalculation}`,
					cost,
					bundleInfo: state.calculations
				}
			});
		}
	},
	modules: {}
});
