<template>
  <div>
    <div class="wrap">
      <div class="bg-lines">
        <div class="line line1"></div>
        <div class="line line2"></div>
        <div class="line line3"></div>
        <div class="line line4"></div>
        <div class="line line5"></div>
        <div class="line line6"></div>
      </div>

<!--      <div class="bg-circles">-->
<!--		  <img src="../assets/img/dist/index/circle-blue.png" alt="" class="bg-circles__left">-->
<!--		  <img src="../assets/img/dist/index/circle-blue.png" alt="" class="bg-circles__right">-->
<!--	  </div>-->
      <div class="front-brands">

        <img src="../assets/img/dist/index/circle-blue.png" alt="" class="circle__brands">
        <img src="../assets/img/dist/index/circle-blue.png" alt="" class="circle__brands2">
      </div>

      <header class="header">

        <!--<img src="assets/img/index/circle-purple.png" alt="" class="circle_purple">-->

        <div class="container header__container">
          <a href="/" class="header__logo">
            <img src="../assets/img/dist/logo.png" alt="">
          </a>
          <nav class="header__nav">
            <a href="daas.html" class="header__nav-link">DAAS</a>
            <a href="trade-in.html" class="header__nav-link">Trade IN</a>
            <a href="http://isurance.ru/" class="header__nav-link" target="_blank">ServicePack</a>
            <a href="#" class="header__nav-link">Вакансии</a>
            <a href="https://crm.isurance.ru" class="header__nav-link"
               target="_blank">Войти&nbsp;в&nbsp;лк</a>
            <a href="contact.html" class="header__nav-link">Контакты</a>
          </nav>
          <div class="header__phone-holder">
            <a href="tel:+74951503494" class="header__phone">+7 (495) 150-34-94</a>
          </div>
        </div>
      </header>

      <div class="home">
        <div class="home__wrapper">
          <div class="home__title">Калькулятор B2B услуг</div>
          <div class="home__group">
            <a class="home__btn" @click="createNewCalculate">Создать новый расчет</a>
          </div>
        </div>
        <div class="calculations">
          <h3 class="calculations__title">Сохраненные расчеты</h3>
          <div class="calculations__wrapper">
            <!--<div class="calculations__empty">Расчетов пока ещё нет</div>-->
            <div class="calculations__item" v-for="calculation of calculations">

              <div class="calculations__name" @click="goToPage(calculation.id)">
                  {{calculation.name}}
              </div>
              <div class="calculations__date">{{calculation.date}}</div>
<!--              <div v-for="item of JSON.parse(calculation.bundleInfo)">-->
<!--                <div v-for="program of item.programPackage">{{program.price.base}}</div>-->
<!--              </div>-->
              <div class="calculations__price">{{calculation.cost | price}} ₽</div>
              <a href="" class="calculations__edit" @click.prevent="goToPage(calculation.id)">Редактировать</a>
              <a href="" class="calculations__delete" @click.prevent="deleteCalculation(calculation.id)">
                <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9997 4.75041H0.996094L2.12136 2.49988H14.8744L15.9997 4.75041Z" stroke="#8799AF" stroke-width="0.85" stroke-linecap="round" stroke-linejoin="round"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M2.87048 17.5036H14.1232V4.75061H2.87048V17.5036Z" stroke="#8799AF" stroke-width="0.85" stroke-linecap="round" stroke-linejoin="round"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.3749 1H6.62396L5.87378 2.50036H11.125L10.3749 1Z" stroke="#8799AF" stroke-width="0.85" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M7.37195 7.00122V15.2532" stroke="#8799AF" stroke-width="0.85" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M9.62256 7.00122V15.2532" stroke="#8799AF" stroke-width="0.85" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M5.12122 7.00122V15.2532" stroke="#8799AF" stroke-width="0.85" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M11.8733 7.00122V15.2532" stroke="#8799AF" stroke-width="0.85" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="bottom-logo">
        <img src="../assets/img/dist/logo-blue.png" alt="" class="bottom-logo__img">
      </div>
      <div class="front-conf">

        <img src="../assets/img/dist/index/circle-blue.png" alt="" class="circle__conf">
      </div>
      <footer class="footer">

        <img src="../assets/img/dist/index/circle-blue.png" alt="" class="circle__footer">

        <div class="container footer__container">
          <div class="footer__copyright">© 2018 Телекоминвест</div>
          <nav class="footer__nav">
            <a href="daas.html" class="footer__nav-link">DAAS</a>
            <a href="trade-in.html" class="footer__nav-link">Trade IN</a>
            <a href="http://isurance.ru/" class="footer__nav-link" target="_blank">ServicePack</a>
            <a href="#" class="footer__nav-link">Вакансии</a>
            <a href="https://crm.isurance.ru" class="footer__nav-link" target="_blank">Войти&nbsp;в&nbsp;лк</a>
            <a href="contact.html" class="footer__nav-link">Контакты</a>
          </nav>
          <div class="footer__phone">+7 (495) 150-34-94</div>
        </div>
      </footer>
    </div>
  </div>
</template>


<!--<script src="../assets/js/vendor/gsap/TweenMax.min.js"></script>-->
<!--<script src="../assets/js/vendor/scrollmagic/ScrollMagic.min.js"></script>-->
<!--<script src="../assets/js/vendor/scrollmagic/animation.gsap.min.js"></script>-->
<script>
// @ is an alias to /src
import {mapActions, mapMutations, mapState} from 'vuex';

export default {
  name: 'Home',
  methods: {
    goToPage(calculationId) {
        this.$router.push({ path: 'devices', query: { calculationId } })
    },
    createNewCalculate() {
      this.$store.dispatch('CREATE_CALCULATION');
    },
    deleteCalculation(calculationId) {
      let filterCalculation = this.calculations.filter(calculation => calculation.id !== calculationId);
      this.$store.commit('changeCalculationInfo', filterCalculation)
      this.$store.dispatch('DELETE_CALCULATION', calculationId);
    }
  },
  computed: {
    ...mapState(['calculations', 'idNewCalculation'])
  },
  mounted() {
    this.$store.dispatch('GET_ALL_CALCULATION');
  }
}
</script>
<style lang="scss">
  @import "src/assets/sass/app";
</style>
